import * as React from 'react'
import { graphql } from 'gatsby'
import {
  ModelHero,
  ModelFeatures,
  SimpleCta,
  ClickToCall,
  Testimonials,
} from '@layouts'
import { Layout, SEO } from '@globals'
import { useQueryParam } from '@hooks'

import { Helmet } from 'react-helmet'

const ModelTemplate = ({ data }) => {
  console.log(useQueryParam(`dealerId`))
  const [value] = useQueryParam(`dealerId`)
  React.useEffect(() => {
    window.sessionStorage.setItem('dealerId', value)
  }, [value])

  const {
    name,
    body,
    price,
    floorplansLink,
    features,
    slug,
    video,
    brochureEmbed,
    testimonials,
  } = data.sanityModel

  return (
    <>
      <SEO
        title={data.sanityModel.name}
        description={data.sanityModel.body}
        disallowRobots="false"
      />
      <Layout>
        <ModelHero name={name} body={body} price={price} video={video} />
        <SimpleCta link={floorplansLink} />
        <ModelFeatures features={features} slug={slug} />
        <Testimonials quotes={testimonials} />
        <ClickToCall model={name} />
        <HubspotForm formID={brochureEmbed} />
      </Layout>
    </>
  )
}

export default ModelTemplate

export const MODEL_QUERY = graphql`
  query ($_id: String!) {
    sanityModel(_id: { eq: $_id }) {
      _id
      name
      body
      price
      floorplansLink
      brochureEmbed
      slug {
        current
      }
      features {
        body
        headingLineOne
        headingLineTwo
        detailsHeading
        details {
          body
        }
        image {
          asset {
            source {
              url
              id
              _key
            }
            altText
            _id
            path
          }
        }
      }
      video {
        url
        thumbnail {
          asset {
            path
            _id
          }
        }
      }
      testimonials {
        body
        location
        personName
      }
    }
  }
`
class HubspotForm extends React.Component {
  componentDidMount() {
    this.initializeHubspotForm()
  }
  initializeHubspotForm() {
    ;(async () => {
      while (!window.hbspt)
        // define the condition as you like
        await new Promise((resolve) => setTimeout(resolve, 1000))
      window.hbspt.forms.create({
        portalId: '151834',
        formId: this.props.formID,
        target: '#form-container',
      })
    })()
  }
  render() {
    return (
      <section className="" data-module="model-CTA">
        <Helmet>
          <script src="//js.hsforms.net/forms/v2.js" type="text/javascript" />
          <script>
          var _hsq = window._hsq = window._hsq || [];
          _hsq.push(['setPath', window.location.href]);
          _hsq.push(['trackPageView']);
          </script>
        </Helmet>
        <h2 className="text-xl font-bold tracking-wider uppercase text-brand-gray-500 sm:text-2xl">
          <span className="block">Want to learn more?</span>
          <span className="block text-brand-red-200">
            Download the brochure.
          </span>
        </h2>
        <div id="form-container" className="mt-8"></div>
      </section>
    )
  }
}
